import { createSlice } from "@reduxjs/toolkit";
import { getAllWorkspaces, deleteWorkspace, createWorkspace, setSelectedWorkspace, inviteMembers } from "./actions/workspaceActions";

const initialState = {
  id: null,
  name: null,
  owner: {},
  members: [],
  workspaces: [],
  loading: false,
  error: null,
  selectedWorkspaceId: null,
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspace: (state, action) => {
      console.log("Action received in setWorkspace:", action.payload);
      if (action.payload) {
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.owner = action.payload.owner;
        state.members = action.payload.members;
      } else {
        console.error("Action payload is undefined or null");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkspaces.fulfilled, (state, action) => {
        console.log("getAllWorkspaces payload:", action.payload); 
        state.workspaces = action.payload; 
        state.loading = false; 
        state.error = null;
      })
      .addCase(getAllWorkspaces.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllWorkspaces.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteWorkspace.fulfilled, (state, action) => {
        state.workspaces = state.workspaces.filter(workspace => workspace.id !== action.payload);
      })
      .addCase(deleteWorkspace.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        console.log("Workspace created successfully:", action.payload);
        state.workspaces.push(action.payload.workspace); 
        state.loading = false;
        state.error = null;
      })
      .addCase(createWorkspace.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWorkspace.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(setSelectedWorkspace.fulfilled, (state, action) => {
        state.selectedWorkspaceId = action.payload;
      })
      .addCase(inviteMembers.fulfilled, (state, action) => {
        console.log("Member invited successfully:", action.payload);
        state.error = null; 
      })
      .addCase(inviteMembers.rejected, (state, action) => {
        state.error = action.payload; 
      });
  }
});

export const { setWorkspace } = workspaceSlice.actions;
export default workspaceSlice.reducer;
