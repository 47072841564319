import { useState, useMemo, useEffect } from "react";
import Card from "../components/Card";
import FilterDropdown from "../components/FilterDropdown";
import SearchInput from "../components/SearchInput";
import SEO from "../components/SEO";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import clients from '../data/clients.json';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContacts } from "../redux/actions/contactActions";
import { createOrganisation, fetchOrganisations, deleteOrganisation, sendInvitation } from "../redux/actions/organisationActions";
import { Avatar } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Menu, MenuItem } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';


function ClientList() {
    const initialFavorites = [clients[0], clients[1], clients[2], clients[3]];
    const [favorites, setFavorites] = useState(initialFavorites);
    const [showAllFavorites, setShowAllFavorites] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [newClient, setNewClient] = useState({
        name: "",
        sector: "",
        email: "",
        description: "",
        country: { name: "" },
        site: ""
    });

    const [value, setValue] = useState(0);

    const toggleFavorite = (client) => {
        setFavorites((prevFavorites) => {
            if (prevFavorites.find(fav => fav.id === client.id)) {
                return prevFavorites.filter(fav => fav.id !== client.id);
            } else {
                return [...prevFavorites, client];
            }
        });
    };

    const nonFavorites = useMemo(() => {
        return clients.filter(client => !favorites.find(fav => fav.id === client.id));
    }, [clients, favorites]);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleNewClientChange = (field, value) => {
        const fields = field.split('.');
        setNewClient((prevClient) => {
            let updatedClient = { ...prevClient };
            fields.reduce((acc, key, idx) => {
                if (idx === fields.length - 1) {
                    acc[key] = value;
                } else {
                    acc[key] = { ...acc[key] };
                }
                return acc[key];
            }, updatedClient);
            return updatedClient;
        });
    };

    const dispatch = useDispatch();
    const { organisations = [], loading, error } = useSelector((state) => state.organisations || {});
    const { contacts = [] } = useSelector((state) => state.contacts || {});

    useEffect(() => {
        dispatch(fetchOrganisations());
        dispatch(getAllContacts());
    }, [dispatch]);



    const handleAddClient = () => {
        const formattedClient = {
            name: newClient.name.charAt(0).toUpperCase() + newClient.name.slice(1),
            sector: newClient.sector.charAt(0).toUpperCase() + newClient.sector.slice(1),
            email: newClient.email,
            description: newClient.description.charAt(0).toUpperCase() + newClient.description.slice(1),
            country: { name: newClient.country.id },
            site: newClient.site,
        };
        console.log(formattedClient); 
    
        // Créer l'organisation
        dispatch(createOrganisation(formattedClient)).then((action) => {
            if (createOrganisation.fulfilled.match(action)) {
                // const contact = formattedClient.email;
                dispatch(sendInvitation({ email: formattedClient.email, name: formattedClient.name }));
            } else {
                console.error(action.payload); 
            }
        });
    
        setNewClient({
            name: "",
            sector: "",
            email: "",
            description: "",
            country: { name: "" },
            site: ""
        });
        handleCloseModal();
    };

    function Link({ texte, lien }) {
        return (
            <button 
                onClick={() => window.open(lien, '_blank')} 
                className='font-bold text-[12px] text-secondary-raven pr-[15px] pl-[15px] pt-[3px] pb-[3px] bg-secondary-background border border-secondary-stroke rounded-2xl flex justify-between items-center mr-[5px]'
            >
                {texte}
            </button>
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getInitials = (name) => {
        const nameParts = name.split(' ');
        return nameParts.map(part => part.charAt(0).toUpperCase()).join('');
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteOrganisation = (organisationId) => {
        dispatch(deleteOrganisation(organisationId));
      };

    return (
        <>
            <SEO title="Client | iTady" description="Liste des sites" />
            <div className="p-[15px] h-screen flex flex-col">
                <div className="flex-1 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel mt-[20px] pb-[20px] pr-[10px] pl-[10px] overflow-auto">
                    <div className="flex justify-between items-baseline pr-[5px] pl-[5px] mt-[15px]">
                        <p className="font-bold text-[20px]">Liste des contacts</p>
                        <div className="flex justify-center items-center">
                            <SearchInput />
                            <FilterDropdown />
                            <button className="ml-2 p-2 bg-blue-500 text-white rounded" onClick={handleOpenModal}>
                                Ajouter
                            </button>
                        </div>
                    </div>

                    {/* Tabs */}
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Organisations" />
                        <Tab label="Personnel" />
                    </Tabs>

                    {/* Tab Panel 1 - Organisations */}
                    <div role="tabpanel" hidden={value !== 0}>
                        {value === 0 && (
                            <div className="mt-[20px]">
                                {loading && <p>Chargement des organisations...</p>}
                                {error && <p className="text-red-500">{error}</p>}
                                <div className="flex flex-wrap overflow-auto justify-center items-center mt-[10px]">
                                    {Array.isArray(organisations) && organisations.map((organisation) => (
                                        organisation && organisation.id ? (
                                            <div key={organisation.id} className="overflow-hidden relative m-2 ml-[20px] mb-[20px] h-[260px] w-[280px] pb-[20px] border-secondary-stroke rounded-xl border-[1px] bg-secondary-white">
                                                <div className="bg-primary-bleu w-full h-[70px] flex items-end relative justify-between pr-[10px] pl-[20px]">
                                                    <Avatar sx={{ width: 60, height: 60, border: '5px solid white' }} className=" absolute bottom-0 translate-y-1/3">{getInitials(organisation.name)}</Avatar>
                                                    <IconButton onClick={handleClick}>
                                                        <MoreHorizRoundedIcon className='h-[10px] w-[10px] text-secondary-ciel mt-[-80px]' />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        PaperProps={{
                                                            className: 'bg-secondary-background',
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleClose} className="flex items-center">
                                                            <EditOutlinedIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                                                            Modifier
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose} className="flex items-center">
                                                            <AddIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                                                            Ajouter RN
                                                        </MenuItem>
                                                        <MenuItem onClick={() => { 
                                                            handleDeleteOrganisation(organisation.id); 
                                                            handleClose();
                                                        }} className="flex items-center">
                                                            <DeleteOutlineOutlinedIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                                                            Supprimer
                                                        </MenuItem>
                                                    </Menu>
                                                </div>

                                                <div className="w-full p-[20px]">
                                                    <p className='text-[18px] font-black' >{organisation.name}</p>
                                                    <p className='font-bold text-[16px] text-secondary-raven'> {organisation.sector}</p>
                                                    <p className='font-bold text-[16px] text-secondary-raven'>{organisation.email}</p>
                                                    <p className='font-bold text-[14px] text-secondary-raven'>{organisation.country?.name || ''}</p>
                                                    <p className='font-bold text-[14px] text-secondary-raven'>{organisation.site?.url || ''}</p>
                                                    <p className='text-[14px] text-secondary-pearl'>{organisation.description}</p>
                                                </div>
                                            </div>
                                        ) : null
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Tab Panel 2 - Contacts */}
                    <div role="tabpanel" hidden={value !== 1}>
                        {value === 1 && (
                            <div className="flex flex-wrap overflow-auto justify-center items-center mt-[10px]">
                                {Array.isArray(contacts) && contacts.map((contact) => (
                                    contact && contact.id ? (
                                        <div key={contact.id} className="overflow-hidden relative m-2 ml-[20px] mb-[20px] h-[280px] w-[280px] pb-[20px] border-secondary-stroke rounded-xl border-[1px] bg-secondary-white">
                                                <div className="bg-primary-bleu w-full h-[70px] flex items-end relative justify-between pr-[10px] pl-[20px]">
                                                    <Avatar sx={{ width: 60, height: 60, border: '5px solid white' }} className=" absolute bottom-0 translate-y-1/3">{getInitials(contact.name)}</Avatar>
                                                    <IconButton onClick={handleClick}>
                                                        <MoreHorizRoundedIcon className='h-[10px] w-[10px] text-secondary-ciel mt-[-80px]' />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        PaperProps={{
                                                            className: 'bg-secondary-background',
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleClose} className="flex items-center">
                                                            <EditOutlinedIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                                                            Modifier
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose} className="flex items-center">
                                                            <AddIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                                                            Ajouter RN
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose} className="flex items-center">
                                                            <DeleteOutlineOutlinedIcon className="h-[16px] w-[16px] mr-[5px] text-secondary-raven" />
                                                            Supprimer
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            {contacts && contacts.length > 0 ? (
                                                contacts.map((contact) => (
                                                    <div key={contact.id} className=" mt-2 pt-2 w-full p-[20px]">
                                                        <p className='text-[18px] font-black'> {contact.name}</p>
                                                        <p className='font-bold text-[14px] text-secondary-raven'>{contact.telephone}</p>
                                                        <p className='font-bold text-[14px] text-secondary-raven'> {contact.email}</p>
                                                        <p className=' text-[14px]'><strong>Poste:</strong> {contact.occupation}</p>
                                                        <p className=' text-[14px]'><strong>Grade:</strong> {contact.grade}</p>
                                                        {contact.social_networks && contact.social_networks.length > 0 && (
                                                            <div className="mt-2 flex flex-wrap space-y-1">
                                                                {contact.social_networks.map((social, socialIndex) => (
                                                                    <div key={socialIndex} className="pl-4">
                                                                        <Link texte={social.platform_name} lien={social.profile_url || "#"} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <p>Aucun contact trouvé.</p>
                                            )}
                                        </div>
                                    ) : null
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Modal for adding a client */}
                <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title">
                    <Box className="bg-white max-w-lg w-full mx-auto p-6 rounded-lg shadow-lg relative" style={{ top: '50%', transform: 'translateY(-50%)' }}>
                        <div className="flex justify-between items-center mb-4">
                            <h2 id="modal-title" className="font-semibold">Ajouter un Organisation</h2>
                            <IconButton onClick={handleCloseModal}>
                                <Close />
                            </IconButton>
                        </div>
                        <div className="space-y-4">
                            <TextField label="Nom" fullWidth variant="outlined" size="small" value={newClient.name} onChange={(e) => handleNewClientChange('name', e.target.value)} />
                            <TextField label="Secteur" fullWidth variant="outlined" size="small" value={newClient.sector} onChange={(e) => handleNewClientChange('sector', e.target.value)} />
                            <TextField label="Email" fullWidth variant="outlined" size="small" value={newClient.email} onChange={(e) => handleNewClientChange('email', e.target.value)} />
                            <TextField label="Description" fullWidth variant="outlined" size="small" value={newClient.description} onChange={(e) => handleNewClientChange('description', e.target.value)} />
                            <TextField label="Pays" fullWidth variant="outlined" size="small" value={newClient.country.id} onChange={(e) => handleNewClientChange('country.id', e.target.value)} />
                            <TextField label="Site Web" fullWidth variant="outlined" size="small" value={newClient.site} onChange={(e) => handleNewClientChange('site', e.target.value)} />
                        </div>
                        <div className="flex justify-between items-center mt-6">
                            <button className="p-2 bg-gray-300 text-black rounded" onClick={handleCloseModal}>Annuler</button>
                            <button className="p-2 bg-blue-500 text-white rounded" onClick={handleAddClient}>Ajouter</button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default ClientList;
