import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const backendURL = process.env.REACT_APP_BACKEND_URL;

// Async thunk to create a configuration
export const createConfiguration = createAsyncThunk(
    'filter/createConfiguration',
    async (data, { rejectWithValue }) => {
        try {
            // Récupération du token depuis le localStorage
            const token = localStorage.getItem('access_token');

            // Configuration de l'en-tête Authorization
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            // Envoi de la requête POST au backend
            const response = await axios.post(`${backendURL}/configurations`, data, config);
            return response.data; // Retour des données reçues du backend
        } catch (error) {
            // Gestion des erreurs
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


// Async thunk to fetch configurations
export const fetchConfigurations = createAsyncThunk(
    'filter/fetchConfigurations',
    async (_, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('access_token');
  
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
  
        const response = await axios.get(`${backendURL}/configurations`, config);
        return response.data; // Retourne les données des configurations
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );

// Async thunk to update a configuration
export const updateConfiguration = createAsyncThunk(
    'filter/updateConfiguration',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            // Envoi de la requête PUT au backend pour mettre à jour la configuration par ID
            const response = await axios.put(`${backendURL}/configurations/${id}`, data, config);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const deleteConfiguration = createAsyncThunk(
    'filter/deleteConfiguration',
    async (id, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('access_token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            // Envoi de la requête DELETE au backend
            await axios.delete(`${backendURL}/configurations/${id}`, config);
            return id; // Retourne l'ID de la configuration supprimée pour la mise à jour de l'état
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const duplicateConfiguration = createAsyncThunk(
    'filter/duplicateConfiguration',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('access_token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            // Envoi de la requête POST au backend pour dupliquer la configuration
            const response = await axios.post(`${backendURL}/configurations/${id}/duplicate`, data, config);
            return response.data; // Retourne la réponse du backend
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
  