import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Primary from "./pages/Primary";
import Menu from "./pages/Menu";
import Workspaces from "./pages/workspace/Workspaces";
import Agent from "./pages/Agent";
import LoginPage from "./pages/auth/LoginPage";
import { RegisterPage } from "./pages/auth/RegisterPage";
import Data from "./pages/Data";
import ClientList from "./pages/ClientList";
import FilterPage from "./pages/FilterPage";
import ContactList from "./pages/ContactList";
import CreateWorkspace from "./pages/workspace/CreateWorkspace";
import InviteMembers from "./pages/workspace/InviteMembers";
import NotFound from "./pages/routing/NotFound";
import ProtectedRoute from "./pages/routing/ProtectedRoute";
import HomePage from "./pages/HomePage";
import Agents from "./pages/agent/Agents";
import ListOffers from "./pages/Offer/ListOffers";

function Main() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to={`workspace`} replace />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/register' element={<RegisterPage />} />
                <Route path='/create-workspace' element={<CreateWorkspace />} />
                <Route element={<ProtectedRoute />}>
                    <Route path='/workspace' element={<Workspaces />} />
                    <Route path="/workspace/:workspaceId" element={<Primary />}>
                        <Route path="" element={<HomePage />} />
                        <Route path="agent" element={<Agents />} />
                        <Route path="donnees" element={<ListOffers />} />
                        <Route path="clients" element={<ClientList />} />
                        <Route path="filtres" element={<FilterPage />} />
                        <Route path="contacts" element={<ContactList />} />
                        <Route path="invite" element={<InviteMembers />} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default Main;