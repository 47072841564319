import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useRef, useState } from 'react';

function Multiselect2({ options = [], placeholder, onChange, value = [] }) {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const inputRef = useRef(null);

    const toggleOption2 = (option) => {
        const newValue = value.includes(option)
            ? value.filter(item => item !== option)
            : [...value, option]
        onChange(newValue)
    }

    const filteredOptions2 = options.filter(option =>
        option.toLowerCase().includes(search.toLowerCase()) &&
        !value.includes(option)
    )
    return (
        <div className="relative">
            {value.length > 0 && (
                <div className="flex flex-wrap gap-2">
                    {value.map((option, index) => (
                        <span
                            key={index}
                            className="inline-flex items-center px-4 py-2 font-bold rounded-full bg-primary-bleu text-white"
                        >
                            {option}
                            <button
                                type="button"
                                className="ml-1 focus:outline-none"
                                onClick={() => toggleOption2(option)}
                            >
                                <CloseRoundedIcon fontSize='small' />
                            </button>
                        </span>
                    ))}
                </div>
            )}

            <div className='my-2'>
                <input
                    ref={inputRef}
                    type="text"
                    className="w-full p-4 border border-secondary-stroke rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-bleu"
                    placeholder={placeholder}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                />
            </div>

            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-300">
                    <ul className="max-h-60 overflow-auto py-1">
                        {filteredOptions2.map((option, index) => (
                            <li
                                key={index}
                                className="px-3 py-2 hover:bg-primary-bleu hover:text-white cursor-pointer"
                                onClick={() => {
                                    toggleOption2(option);
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default Multiselect2;