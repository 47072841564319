import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#001751',
            contrastText: '#fff',
        },
        secondary: {
            main: '#FFBA13',
            contrastText: '#000',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: '0.5rem',  // Rayon de la bordure
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none', // Enlève la transformation en majuscules
                    fontSize: '1rem',       // Taille de la police
                    fontWeight: 'medium',     // Épaisseur du texte
                },
            },
        },
    },
});

export default theme;