import apiClient from "../utils/apiClient";
import baseService from "./baseService";

const userService = {
    ...baseService('user'),
    // Specific method
    me: async () => {
        return await apiClient.get(`/auth/me`);
    },

    logout: async () => {
        return await apiClient.get(`/auth/logout`);
    },

    fakeMe: async () => {
        return {
            id: 104,
            firstname: 'julie',
            lastname: 'be',
            email: "julie@marketing.com",
            role: 1
        }
    }
}

export default userService;