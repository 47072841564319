import itady from '../assets/images/itady.png';
import ai from '../assets/images/AI.png';
import uiux from '../assets/images/UIUX.png';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

function HBarHome(){
    return(
        <div className='flex justify-between items-center bg-primary-bleu rounded-xl p-5 relative'>
            <div>
                <img src={itady} alt='' className='h-[24px] w-[60px]'/>
                <p className='font-bold text-secondary-white text-[18px] w-[300px]'>En quoi est-ce qu’on peut vous aider aujourd’hui?</p>
                <p className=' font-bold text-secondary-background text-[10px]'>Creer un agent</p>
            </div>
            <div className='flex justify-center items-center space-x-2'>
                <div className='h-[100px] w-[90px] border-2 border-dotted border-secondary-white rounded-xl flex items-center justify-center'>
                    <AddOutlinedIcon className='text-secondary-white'/>
                </div>
                <div className='h-[100px] w-[90px] rounded-xl bg-secondary-white flex items-center justify-center'>
                    <img src={ai} alt='' className='h-[50px] w-[50px]'/>
                </div>
                <div className='h-[100px] w-[90px] rounded-xl bg-secondary-white flex items-center justify-center'>
                    <img src={uiux} alt='' className='h-[50px] w-[50px]'/>
                </div>
            </div>
        </div>

    )
}
export default HBarHome;