import { createSlice } from '@reduxjs/toolkit';
import { fetchAgents } from './actions/agentActions';

const initialState = {
  agents: [],
  loading: false,
  error: null,
};

const agentlistSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload; 
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default agentlistSlice.reducer;
