import { useEffect, useState } from "react";
import MyCheckBox from "../../components/inputs/MyCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { setSize } from "../../redux/filterSlice";

function SizeFilter() {

    const [checkedItems, setCheckedItems] = useState({
        small: false,
        medium: true,
        large: false,
    })

    const { size } = useSelector(state => state.filter);
    const dispatch = useDispatch();

    useEffect(() => {
        // setCheckedItems(size);
        // console.log(sectors);
        
    }, [size]);

    const handleCheckboxChange = (isChecked, value) => {
        const newState = {
            ...checkedItems,
            [value]: isChecked
        };
        setCheckedItems(newState);
        dispatch(setSize(newState));
    }

    return (
        <div>
            <div className="text-center mb-5">
                <h1 className="text-3xl font-extrabold">Veuillez sélectionner la taille de l’entreprise</h1>
                <h3>Choisissez la catégorie qui correspond le mieux à la taille de l’entreprise que vous ciblez.</h3>
            </div>
            <div className="flex justify-center">
                <div className="w-[500px] max-w-[500px]">
                    <div className="flex flex-col justify-center items-center gap-2">
                        <MyCheckBox
                            onChange={handleCheckboxChange}
                            label="Petit"
                            checked={checkedItems.small}
                            value={'small'}
                        />

                        <MyCheckBox
                            onChange={handleCheckboxChange}
                            label="Moyenne"
                            checked={checkedItems.medium}
                            value={'medium'}
                        />

                        <MyCheckBox
                            onChange={handleCheckboxChange}
                            label="Grand"
                            checked={checkedItems.large}
                            value={'large'}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SizeFilter;