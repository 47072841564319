import { createSlice } from "@reduxjs/toolkit";
import { getAllContacts } from "./actions/contactActions"; // Assurez-vous que le chemin est correct

const initialState = {
  contacts: [],
  loading: false,
  error: null,
};

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContacts.fulfilled, (state, action) => {
        console.log("getAllContacts payload:", action.payload); 
        state.contacts = action.payload; 
        state.loading = false; 
        state.error = null;
      })
      .addCase(getAllContacts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllContacts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export default contactSlice.reducer;