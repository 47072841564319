import { Outlet, useParams } from "react-router-dom";
import SideBar from "../components/SiderBar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import workspaceService from "../services/workspaceService";
import { setW } from "../redux/wSlice";

function Primary() {

    const { workspaceId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchWorkspace = async () => {
            try {
                const response = await workspaceService.getById(workspaceId);
                if(response.data) {
                    dispatch(setW(response.data));
                }
            } catch (error) {
                
            }
        };
        
        fetchWorkspace();
    }, []);

    return (
        <div className="flex h-screen overflow-hidden">
            <SideBar />

            <div className="h-screen overflow-auto flex-grow">
                <Outlet />
            </div>
        </div>
    );
}

export default Primary;