import React from 'react';
import { IconButton, LinearProgress } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function NotificationCard() {
  return (
    <div className="w-full h-[180px] bg-secondary-white rounded-lg pr-[35px] pl-[35px] pb-[20px] pt-[20px] flex flex-col justify-between border border-secondary-stroke">
      {/* Top Section */}
      <div className="flex justify-between items-start">
        <div>
          <p className="font-bold text-secondary-raven text-[12px]">Notification this week</p>
          <p className="text-4xl font-bold text-secondary-pearl">15</p>
        </div>
        <IconButton>
          <MoreHorizIcon />
        </IconButton>
      </div>

      {/* Bottom Section */}
      <div className="flex flex-col w-full mt-4">
        <p className="text-sm font-bold text-secondary-pearl mb-2">Performance review</p>
        <LinearProgress
          variant="determinate"
          value={50}
          sx={{
            height: 10,
            borderRadius: 5,
            backgroundColor: '#e5e7eb',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#001751', 
            },
          }}
        />
      </div>
    </div>
  );
}

export default NotificationCard;
