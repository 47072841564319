// CHART 
import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart, Area
} from 'recharts';
import { Typography } from '@mui/material';

const data = [
    { name: 'Jan', devTeam: 20, offers: 35 },
    { name: 'Feb', devTeam: 30, offers: 40 },
    { name: 'Mar', devTeam: 20, offers: 20 },
    { name: 'Apr', devTeam: 40, offers: 40 },
    { name: 'May', devTeam: 65, offers: 60 },
    { name: 'Jun', devTeam: 40, offers: 75 },
    { name: 'Jul', devTeam: 87, offers: 50 },
  ];

const PerformanceChart = () => {
  return (
    <div className="w-full p-4 bg-secondary-white rounded-lg ">
      <p className="font-bold mb-4 text-[20px] ml-[30px]">Agent Performance</p>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorDev" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#003366" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#003366" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorOffers" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#808080" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#808080" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area type="monotone" dataKey="devTeam" stroke="#003366" fillOpacity={1} fill="url(#colorDev)" />
          <Area type="monotone" dataKey="offers" stroke="#808080" fillOpacity={1} fill="url(#colorOffers)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerformanceChart;
