import apiClient from "../utils/apiClient";

const baseService = (resourceUrl) => ({
    // Create (POST)
    create: async (data) => {
      return await apiClient.post(`/${resourceUrl}`, data);
    },
  
    // Read (GET) - Get all items
    getAll: async () => {
      return await apiClient.get(`/${resourceUrl}`);
    },
  
    // Read (GET) - Get an item by ID
    getById: async (id) => {
      return await apiClient.get(`/${resourceUrl}/${id}`);
    },
  
    // Update (PUT) - Update an item by ID
    update: async (id, data) => {
      return await apiClient.put(`/${resourceUrl}/${id}`, data);
    },
  
    // Delete (DELETE) - Delete an item by ID
    delete: async (id) => {
      return await apiClient.delete(`/${resourceUrl}/${id}`);
    },
  });
  
  export default baseService;