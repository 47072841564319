import React from 'react';
import Dialog from '@mui/material/Dialog';

const ConfirmDialog = ({ open, message, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <div className='p-2 text-primary-bleu'>
                <div className='text-xl font-extrabold'>
                    Confirmation
                </div>
                <hr />
                <div className='p-4 my-2'>
                    <h1>
                        {message || 'Êtes-vous sûr de vouloir effectuer cette action ?'}
                    </h1>
                </div>
                <div className='flex justify-end items-center'>
                    <div className='space-x-2'>
                        <button
                            onClick={onClose}
                            className="text-white bg-primary-jaune hover:bg-primary-jaune/80 font-medium rounded-lg text-sm px-4 py-2"
                        >
                            Annuler
                        </button>
                        <button
                            onClick={onConfirm}
                            className="text-white bg-primary-bleu hover:bg-primary-bleu/80 font-medium rounded-lg text-sm px-4 py-2"
                        >
                            Confirmer
                        </button>
                    </div>
                </div>
            </div>


        </Dialog>
    );
};

export default ConfirmDialog;
