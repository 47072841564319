import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import { setUserInfo } from '../../redux/authSlice';
import userService from '../../services/userService';

const ProtectedRoute = () => {
    //Get user profile

    const { accessToken, userInfo } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await userService.me();
                if(response.data) {
                    dispatch(setUserInfo(response.data.data));
                }
            } catch (error) {
                console.error(error.message);
            }
        };
        fetchUserInfo();
    }, []);

    if (!accessToken || !userInfo) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-secondary-background p-4">
                <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-xl shadow-2xl">
                    <div className="text-center">
                        <h2 className="mt-6 text-3xl font-extrabold text-primary-bleu">Accès non autorisés</h2>
                        <p className="mt-2 text-sm text-gray-600">Veuillez vous connecter pour continuer</p>
                    </div>
                    <div className="mt-8 space-y-6">
                        <div className="flex items-center justify-center">
                            <div className="h-24 w-24 rounded-full bg-primary-jaune flex items-center justify-center">
                                <svg
                                    className="h-12 w-12 text-primary-bleu"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                    />
                                </svg>
                            </div>
                        </div>
                        <Link
                            to={"/login"}
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-primary-bleu bg-primary-jaune hover:bg-primary-jaune/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primabg-primary-jaune"
                        >
                            Se connecter
                        </Link>
                    </div>
                    <div className="mt-6 text-center">
                        <p className="text-sm text-gray-600">
                            Vous n'avez pas de compte ? {' '}
                            <Link to={'/register'} className="font-medium text-primary-bleu hover:text-primary-bleu/80">
                                S'inscrire
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    // returns child route elements
    return <Outlet />
}
export default ProtectedRoute;