import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfigurations } from "../redux/actions/filterActions";

function ConfigurationsList({ onConfigClick }) {
  const dispatch = useDispatch();
  const { configurations, fetchStatus } = useSelector((state) => state.filter);

  useEffect(() => {
    dispatch(fetchConfigurations());
  }, [dispatch]);

  if (fetchStatus === 'loading') {
    return <div>Chargement...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Erreur lors du chargement des configurations.</div>;
  }

  return (
    <div className="p-5 pr-30 pl-30">
      <h2 className="text-xl font-bold mb-5">Liste des Configurations</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        {configurations.map((config) => (
          <div
            key={config.id}
            className="overflow-hidden relative m-2 ml-10 mb-[20px] h-[200px] w-[200px] p-[20px] border-secondary-stroke rounded-xl border-[1px] bg-secondary-white"
            onClick={() => onConfigClick(config)}
          >
            <p><strong>Fréquence:</strong> {config.frequency}</p>
            <p><strong>Sécurisé:</strong> {config.issecured ? "Oui" : "Non"}</p>
            <p><strong>Pays:</strong> {config.country}</p>
            <p><strong>Tailles:</strong></p>
            <ul>
              <li>Petite: {config.small ? "True" : "False"}</li>
              <li>Moyenne: {config.medium ? "True" : "False"}</li>
              <li>Grande: {config.large ? "True" : "False"}</li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ConfigurationsList;
