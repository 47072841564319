import { createSlice } from "@reduxjs/toolkit";
import { createConfiguration, fetchConfigurations, updateConfiguration, deleteConfiguration, duplicateConfiguration } from "./actions/filterActions";

const initialState = {
    countries: [],
    sectors: '',
    size: {
      small: true,
      medium: true,
      large: false,
    },
    configurations: [], 
    creationStatus: null,
    fetchStatus: null, 
    updateStatus: null, 
    deleteStatus: null,
    duplicateStatus: null
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setSize: (state, action) => {
            state.size = action.payload;
        },
        setSectors: (state, action) => {
            state.sectors = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createConfiguration.pending, (state) => {
                state.creationStatus = 'loading';
            })
            .addCase(createConfiguration.fulfilled, (state) => {
                state.creationStatus = 'succeeded';
            })
            .addCase(createConfiguration.rejected, (state) => {
                state.creationStatus = 'failed';
            })
            .addCase(fetchConfigurations.pending, (state) => {
                state.fetchStatus = 'loading';
            })
            .addCase(fetchConfigurations.fulfilled, (state, action) => {
                state.fetchStatus = 'succeeded';
                state.configurations = action.payload;
            })
            .addCase(fetchConfigurations.rejected, (state) => {
                state.fetchStatus = 'failed';
            })
            .addCase(updateConfiguration.pending, (state) => {
                state.updateStatus = 'loading';
            })
            .addCase(updateConfiguration.fulfilled, (state) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(updateConfiguration.rejected, (state) => {
                state.updateStatus = 'failed';
            })
            .addCase(deleteConfiguration.pending, (state) => {
                state.deleteStatus = 'loading';
            })
            .addCase(deleteConfiguration.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
                // Supprime la configuration de l'état
                state.configurations = state.configurations.filter(config => config.id !== action.payload);
            })
            .addCase(deleteConfiguration.rejected, (state) => {
                state.deleteStatus = 'failed';
            })
            .addCase(duplicateConfiguration.pending, (state) => {
                state.duplicateStatus = 'loading';
            })
            .addCase(duplicateConfiguration.fulfilled, (state, action) => {
                state.duplicateStatus = 'succeeded';
                state.configurations.push(action.payload); // Ajoute la nouvelle configuration à l'état
            })
            .addCase(duplicateConfiguration.rejected, (state) => {
                state.duplicateStatus = 'failed';
            });
    },
});

export const { setCountries, setSectors, setSize } = filterSlice.actions;
export default filterSlice.reducer;
