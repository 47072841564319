import { useState } from "react";
import FilterDropdown from "../components/FilterDropdown";
import SearchInput from "../components/SearchInput";
import ContactCard from "../components/ContactCard";
import contacts from '../data/contact.json';
import SEO from "../components/SEO";

function ContactList() {
    const initialFavorites = [
        contacts[0],
        contacts[1],
        contacts[2],
        contacts[3]
    ];

    const [favorites, setFavorites] = useState(initialFavorites);
    const [showAllFavorites, setShowAllFavorites] = useState(false);

    const toggleFavorite = (contact) => {
        setFavorites((prevFavorites) => {
            if (prevFavorites.find(fav => fav.email === contact.email)) {
                return prevFavorites.filter(fav => fav.email !== contact.email);
            } else {
                return [...prevFavorites, contact];
            }
        });
    };

    const nonFavorites = contacts.filter(contact => !favorites.find(fav => fav.email === contact.email));

    return (
        <>
            <SEO title="Contact | iTady" description="Liste des contacts" />
            <div className="p-[15px] h-screen flex flex-col">
                <div className="flex-1 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel mt-[20px] pb-[20px] pr-[10px] pl-[10px] overflow-auto">
                    <div className="flex justify-between items-baseline pr-[5px] pl-[5px] mt-[15px]">
                        <p className="font-bold text-[20px]">Liste des contacts</p>
                        <div className="flex justify-center items-center">
                            <SearchInput />
                            <FilterDropdown />
                        </div>
                    </div>

                    {favorites.length > 0 && (
                        <div className="mt-[20px]">
                            <div className="flex justify-between items-center pr-[5px] pl-[5px]">
                                <p className="font-semibold text-[18px]">Favoris</p>
                            </div>
                            <div className="flex flex-wrap overflow-auto justify-center items-center mt-[10px]">
                                {(showAllFavorites ? favorites : favorites.slice(0, 8)).map((fav) => (
                                    <div key={fav.email} className="relative m-2">
                                        <ContactCard contact={fav} />
                                        <button 
                                            className="absolute top-2 right-2"
                                            onClick={() => toggleFavorite(fav)}
                                        >
                                            <span style={{ color: 'gold' }}>★</span>
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {favorites.length > 8 && (
                                <div className="flex justify-end mt-[10px]">
                                    <button
                                        className="p-2 bg-blue-500 text-white rounded"
                                        onClick={() => setShowAllFavorites(!showAllFavorites)}
                                    >
                                        {showAllFavorites ? 'Voir moins' : 'Voir plus'}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {nonFavorites.length > 0 && (
                        <div className="mt-[20px]">
                            {favorites.length > 0 && (
                                <div className="flex justify-between items-center pr-[5px] pl-[5px]">
                                    <p className="font-semibold text-[18px]">Autres contacts</p>
                                </div>
                            )}
                            <div className="flex flex-wrap overflow-auto justify-center items-center mt-[10px]">
                                {nonFavorites.map((contact) => (
                                    <div key={contact.email} className="relative m-2">
                                        <ContactCard contact={contact} />
                                        <button 
                                            className="absolute top-2 right-2"
                                            onClick={() => toggleFavorite(contact)}
                                        >
                                            <span style={{ color: 'gray' }}>☆</span>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ContactList;
