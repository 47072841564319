function AgentCard(){
    return(
        <div className='flex flex-col w-full h-[180px] pl-[35px] pb-[20px] pt-[20px] justify-between rounded-lg border-secondary-raven border-2 border-dotted'>
        <div className='space-y-0'>
            <p className="text-[12px] font-bold text-secondary-raven">Temps d’activation d’un agent</p>
            <p className="text-5xl font-bold text-secondary-pearl">
            9.2 <span className="text-4xl">h</span>
            </p>
        </div>
        <p className='text-[18px] font-bold text-secondary-pearl w-[280px]'>Comparaison par rapport à la semaine dernière</p>
        </div>

    )
}
export default AgentCard;