import { useDispatch, useSelector } from 'react-redux';
import { createAgent } from '../redux/actions/agentActions';
import AddIcon from '@mui/icons-material/Add';
import { TextField, IconButton } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useState } from 'react';
import AgentList from "../components/AgentList";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const AgentExample = ({ IconProject, title }) => {
    return (
        <div className='flex justify-between items-center pl-[10px] pr-[10px] rounded-xl border-[1px] border-secondary-stroke w-[250px] h-[50px] bg-secondary-background'>
            <div className='flex justify-center items-center'>
                <div className='p-[5px] bg-secondary-ciel rounded-xl justify-between items-center mr-[10px] border-[1px] border-secondary-stroke'>
                    <IconProject className='text-primary-bleu' />
                </div>
                <p className='font-bold'>{title}</p>
            </div>
            <AddIcon className='text-secondary-stroke opacity-0' />
        </div>
    );
}

function Agent() {
    const dispatch = useDispatch();
    const workspaceId = useSelector((state) => state.workspace.selectedWorkspaceId); 
    // console.log("workspace ID", workspaceId)
    const [inputName, setInputName] = useState("");
    const [inputStatus, setInputStatus] = useState("");
    const { userInfo } = useSelector(state => state.auth);
    
    // Get access token from the auth state in Redux
    const accessToken = useSelector((state) => state.auth.accessToken);

    const handleSend = () => {
        if (!inputName || !inputStatus) {
            alert("Please fill in both name and status.");
            return;
        }

        console.log("Workspace ID: ", workspaceId);
        dispatch(createAgent({
            name: inputName,
            status: inputStatus,
            workspace_id: workspaceId 
        }));
        setInputName("");
        setInputStatus("");
    };

    return (
        <div className='p-[15px] h-screen flex flex-col'>
            <div className="flex items-center w-full justify-end">
                    <div className="flex">
                        <button type="button" className="inline-flex items-center px-5 py-2 text font-medium text-center text-white bg-primary-bleu rounded-lg hover:bg-primary-bleu/80">
                            Notifications
                            <span className="inline-flex items-center justify-center w-5 h-5 ms-2 text-xs font-semibold text-primary-bleu bg-primary-jaune rounded-full">
                                2
                            </span>
                        </button>
                    </div>
                    <div className="p-[1px] bg-gray-400 mx-2 h-5">

                    </div>
                    <div className="space-x-1 text-primary-bleu hover:text-primary-jaune hover:underline cursor-pointer">
                        <span><AccountCircleRoundedIcon /></span>
                        <span>{userInfo.email}</span>
                    </div>
            </div>

            <div className='flex-1 mt-[20px] pb-[20px] flex justify-between items-start border-2 rounded-lg bg-secondary-ciel'>
                <div className="pt-[80px] pb-[-80px] flex-1 mt-[20px] flex flex-col justify-center items-center space-y-6 ">
                    <div className='items-center justify-center'>
                        <h1 className="font-bold text-[35px] text-center">Créer un Agent</h1>
                        <p className="w-[350px] text-center font-regular text-secondary-raven">
                            Un agent est celui qui s'occupe de faire les recherches sur le web a notre place. 
                        </p>
                    </div>
                    {/* Input fields */}
                    <div className="w-[530px] h-[50px] rounded-xl mt-auto flex justify-center items-center p-1 bg-secondary-white border-[1px]">
                        <TextField
                            value={inputName}
                            onChange={(e) => setInputName(e.target.value)}
                            variant="outlined"
                            placeholder="Nom"
                            fullWidth
                            InputProps={{
                                style: {
                                    texte: 'black'
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent', 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            className="mr-2"
                        />
                    </div>
                    <div className="w-[530px] h-[50px] rounded-xl mt-auto flex justify-center items-center p-1 bg-secondary-white border-[1px]">
                        <TextField
                            value={inputStatus}
                            onChange={(e) => setInputStatus(e.target.value)}
                            variant="outlined"
                            placeholder="Status"
                            fullWidth
                            InputProps={{
                                style: {
                                    texte: 'black'
                                },
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'transparent', 
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'transparent',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'transparent',
                                    },
                                },
                            }}
                            className="mr-2"
                        />
                    </div>
                    <button
                        className='bg-primary-bleu text-secondary-white font-bold w-[530px] h-[50px] items-center justify-center rounded-md p-[10px] mt-4'
                        onClick={handleSend}
                    > 
                        <AddCircleRoundedIcon  className='mr-4'/>
                        Créer agent
                    </button>
                </div>
                <AgentList />
            </div>
        </div>
    );
}

export default Agent;
