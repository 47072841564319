import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function SEO({ title, description }) {
    const location = useLocation();

    useEffect(() => {
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', description);
        } else {
            const meta = document.createElement('meta');
            meta.name = 'description';
            meta.content = description;
            document.head.appendChild(meta);
        }

    }, [title, description, location.pathname]);

    return null;
}

export default SEO;
