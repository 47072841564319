import workspaceService from "../../services/workspaceService";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/authSlice";
import { useEffect, useState } from "react";
import { useSnackbar } from "../../context/SnackBarProvider";
import useConfirmDialog from "../../hook/useConfirm";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import userService from "../../services/userService";
import { getAllWorkspaces,deleteWorkspace, setSelectedWorkspace } from "../../redux/actions/workspaceActions";

function Workspaces() {
    const { userInfo } = useSelector(state => state.auth);
    const [workspaces, setWorkspaces] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const showAlert = useSnackbar();
    const { confirmDialog, ConfirmationDialog } = useConfirmDialog();

    const handleLogout = async () => {
        const confirm = await confirmDialog('Voulez-vous vraiment vous déconnecter ?');
        if (confirm) {
            try {
                await userService.logout();
                navigate('/login');
                dispatch(logout());
                showAlert('Vous êtes déconnecté', 'info');
            } catch (error) {
                showAlert(error.message, 'error');
            }
        }
    };

    
    const handleWorkspace = (workspace) => {
        if (!workspace || !workspace.id) {
            console.error('Workspace or workspace ID is undefined');
            return;
        }
        dispatch(setSelectedWorkspace(workspace.id)); // Stockez l'ID dans le state
        navigate(`${workspace.id}`);
    };


    

    const handleDelete = async (event, id) => {
        event.stopPropagation();
        const confirm = await confirmDialog('Voulez-vous vraiment supprimer ce workspace ?');
        if (confirm) {
            try {
                // Delete workspace
                const response = await workspaceService.delete(id);
                if (response) showAlert('Le workspace a été supprimé', 'info');
                setWorkspaces(workspaces.filter(w => w.id !== id));
                
            } catch (error) {
                showAlert(error.message, 'error');
            }
        }
    };

    useEffect(() => {
        // Charger les workspaces au montage du composant
        const fetchWorkspaces = async () => {
            try {
                let response  = await workspaceService.getAll()
                if (response.data) {
                    console.log(response.data);
                    setWorkspaces(response.data);
                }
                
            } catch (error) {
                
            }
        };

        fetchWorkspaces();
    }, []);
    
    return (
        <div className="min-h-screen flex flex-col bg-white text-secondary-white">
            <header className="py-6 px-4 sm:px-6 lg:px-8 bg-primary-bleu">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-secondary-white">
                        <strong className="text-primary-jaune">IT</strong>ady
                    </h1>
                    <button onClick={handleLogout} className="text-secondary-white hover:text-primary-jaune transition-colors font-extrabold">
                        Se déconnecter
                    </button>
                </div>
            </header>
    
            <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary-bleu">
                    Choisir un espace de travail
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {workspaces.length > 0 && workspaces
                    .filter(workspace => workspace && workspace.id)
                    .map((workspace) => (
                    // console.log("testa"),
                    workspace.owner && workspace.members && (
                        <div 
                            onClick={() => handleWorkspace(workspace)}
                            key={workspace.id}
                            className="bg-white rounded-lg p-6 hover:shadow-lg transition-shadow overflow-hidden cursor-pointer border-2 border-gray-200"
                        >
                            <h3 className="w-full flex items-center gap-2 text-xl font-semibold mb-2 text-primary-bleu">
                                <span>{workspace.name}</span>
                                <span className="text-sm text-secondary-raven">
                                    (owner: {workspace.owner.email === userInfo?.email ? 'vous' : workspace.owner.email})
                                </span>
                            </h3>
                            <div className="flex justify-between items-center">
                                <p className="text-primary-jaune font-medium">
                                    {workspace.members.length} participant{workspace.members.length !== 1 && "s"}
                                </p>
                                {
                                    workspace.owner.email === userInfo?.email &&
                                    <button onClick={(e) => handleDelete(e, workspace.id)} className="text-red-600 p-1 rounded-full hover:bg-red-100">
                                        <DeleteForeverIcon />
                                    </button>
                                }
                            </div>
                        </div>
                    )
                    ))}

                    <Link to={`/create-workspace`} className="bg-primary-bleu text-white rounded-lg p-6 shadow-md hover:bg-primary-bleu/90 transition-colors cursor-pointer flex items-center justify-center">
                        <div>
                            <span className="text-xl font-semibold">+ Créer un workspace</span>
                        </div>
                    </Link>
                </div>
                <ConfirmationDialog />
            </main>
    
            <footer className="py-6 px-4 sm:px-6 lg:px-8 text-center bg-gray-100">
                <p className="text-primary-bleu/60">
                    © 2024 AKATA GOAVANA. All rights reserved.
                </p>
            </footer>
        </div>
    );
    
}

export default Workspaces;