import { Link, useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { MyInput } from "../../components/inputs/MyInput";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "../../context/SnackBarProvider";
import { createWorkspace as createWorkspaceAction } from "../../redux/actions/workspaceActions";
import workspaceService from "../../services/workspaceService";

function CreateWorkspace() {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const showAlert = useSnackbar();

    const ownerId = 1;

    const handleSubmit = async (e) => {
        e.preventDefault();
        await handleCreateWorkspace();
    };

    const handleCreateWorkspace = async () => {
        if (userInfo) {
            const newWorkspaceData = {
                name: name,
                owner: {
                    email: userInfo.email,
                    id: ownerId,
                },
            };
            try {
                const response = await workspaceService.create(newWorkspaceData);
                if (response) {
                    showAlert('Workspace créé avec succès', 'success');
                    const workspaceId = response.data.workspace.id;
                    navigate(`/workspace/${workspaceId}/invite`);
                }
            } catch (error) {
                console.error("Error creating workspace:", error);
                showAlert(error.message, 'error');
            }
        }
    };

    return (
        <div className="min-h-screen flex flex-col bg-white text-primary-bleu">
            <header className="py-6 px-4 sm:px-6 lg:px-8 bg-primary-bleu">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <Link to={`/workspace`} className="text-secondary-white hover:text-primary-jaune transition-colors font-extrabold">
                        <ArrowBackRoundedIcon />
                        <span className="underline"> Retour</span>
                    </Link>
                </div>
            </header>

            <main className="flex-grow flex flex-col justify-center items-center">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary-bleu">
                    Créer un espace de travail
                </h2>
                <div className="flex justify-center">
                    <form onSubmit={handleSubmit} className="w-[700px] max-w-[700px]">
                        <div className="mb-5">
                            <label className="font-extrabold mb-2">Nom de l'espace de travail</label>
                            <MyInput
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name='workspace-name'
                                placeholder='Entrer le nom du workspace'
                                required
                            />
                        </div>

                        <div>
                            <button className="p-4 bg-primary-bleu rounded-lg w-full text-white font-extrabold hover:text-primary-jaune">+ Créer le workspace</button>
                        </div>
                    </form>
                </div>
            </main>

            <footer className="py-6 px-4 sm:px-6 lg:px-8 text-center bg-gray-100">
                <p className="text-primary-bleu/60">
                    © 2024 AKATA GOAVANA. All rights reserved.
                </p>
            </footer>
        </div>
    );
}

export default CreateWorkspace;
