import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';

export function IconInput({ icon , className, placeholder, type = 'text', ...props }) {
  return (
    <div className={`relative ${className}`}>
      <div className="absolute inset-y-0 start-0 flex items-center ps-3.5">
        {icon ? icon: <PanoramaFishEyeOutlinedIcon fontSize='small' />}
      </div>
      <input
        type={type}
        className="bg-grey border border-secondary-stroke text-secondary-pearl rounded-lg outline-none focus:ring-primary-bleu focus:ring-2 block w-full ps-10 p-4"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
}