import apiClient from "../utils/apiClient"
import baseService from "./baseService"

const fakes = [
    {
        id: 1,
        name: "Redesign",
        owner: {
            id: 101,
            email: "tafitagiovanni0@gmail.com"
        },
        members: [
            { id: 201, name: "Alex" },
            { id: 202, name: "Clara" }
        ]
    },
    {
        id: 2,
        name: "AppDev",
        owner: {
            id: 102,
            email: "paul@startup.com"
        },
        members: [
            { id: 203, name: "Lucas" },
            { id: 204, name: "Marie" }
        ]
    },
    {
        id: 3,
        name: "SEO",
        owner: {
            id: 103,
            email: "julie@marketing.com"
        },
        members: [
            { id: 205, name: "Emma" },
            { id: 206, name: "Nico" }
        ]
    }
];

const workspaceService = {
    ...baseService('workspaces'),
    // Specific Methods  

    invite: async (workspaceId, email) => {
        return await apiClient.post(`/${workspaceId}/invite`, { email });
    },

    getFakes: async () => {
        return fakes;
    },

    getFake: async (id) => {
        return fakes.filter(w => w.id === id)[0];
    },

    createFake: async (data) => {
        return {
            workspace: {
                id: 'workspace_id',
                name: "Workspace Name",
                owner: {
                    email: "owner@example.com",
                    id: 1
                }
            }
        }
    }
}

export default workspaceService;