import axios from "axios";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setWorkspace } from "../workspaceSlice";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const createWorkspace = createAsyncThunk(
  'workspace/create',
  async (workspaceData, { dispatch, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      };

      const { data } = await axios.post(
        `${backendURL}/workspaces`, 
        workspaceData, 
        config
      );

      const workspaceDetails = {
        id: data.workspace.id,
        name: data.workspace.name,
        owner: data.workspace.owner,
        members: [] 
      };

      console.log("Response from backend:", data);
      dispatch(setWorkspace(workspaceDetails)); 
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllWorkspaces = createAsyncThunk(
  'workspace/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      };

      const { data } = await axios.get(
        `${backendURL}/workspaces`, 
        config
      );
      console.log("Fetched workspaces:", data);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteWorkspace = createAsyncThunk(
  'workspace/delete',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      };

      await axios.delete(`${backendURL}/workspaces/${id}`, config);
      return id; 
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const setSelectedWorkspace = createAsyncThunk(
  'workspace/setSelected',
  async (workspaceId, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setWorkspace({ id: workspaceId }));

      return workspaceId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const inviteMembers = createAsyncThunk(
  'workspace/inviteMembers',
  async ({ workspaceId, email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      };

      const response = await axios.post(
        `${backendURL}/workspaces/${workspaceId}/invite`, 
        { email }, 
        config
      );

      return response.data; // Assurez-vous de retourner les données appropriées
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);