export function getAccessToken () {
    return localStorage.getItem('access_token');
}

export function getRefreshToken () {
    return localStorage.getItem('refresh_token');
}

export function removeToken () {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
}