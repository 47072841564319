import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    id: null,
    name: null,
    owner: null,
    members: [],
}

const wSlice = createSlice({
    name: 'w',
    initialState,
    reducers: {
        setW: (state, action) => {
            if (action.payload) {
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.owner = action.payload.owner;
                state.members = action.payload.members;
            } else {
                console.error("Action payload is undefined or null");
            }
        },
    }
});

export const { setW } = wSlice.actions; 
export default wSlice.reducer;