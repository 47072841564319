import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import filterReducer from './filterSlice'
import workspaceReducer from './workspaceSlice'
import agentlistSlice from './agentlistSlice'
import organisationReducer from './organisationSlice'
import sitelistReducer from './siteSlice'
import contactReducer from './contactSlice'
import wReducer from './wSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    filter: filterReducer,
    workspace: workspaceReducer,
    agents : agentlistSlice,
    organisations : organisationReducer,
    sites: sitelistReducer,
    contacts: contactReducer,
    w: wReducer,
  }
})
export default store
