import React from 'react';
import { IconButton, Badge, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
function EfficiencyCard() {
  return (
    <div className="w-full h-[180px] bg-[#C2C7D4] rounded-lg pr-[35px] pl-[35px] pb-[20px] pt-[20px] flex justify-between items-center">
      {/* Left Section */}
      <div className="flex flex-col justify-between h-full">
        <div className='space-y-0'>
            <p className="text-[12px] font-bold text-secondary-raven">Efficiency</p>
            <p className="text-5xl font-bold text-secondary-pearl">
            87 <span className="text-4xl">%</span>
            </p>
        </div>


        <div className="flex items-center space-x-[-10px] mt-2">
          <div className="bg-secondary-pearl w-10 h-10 rounded-full"></div>
          <div className="bg-secondary-raven w-10 h-10 rounded-full"></div>
          <div className="bg-primary-jaune w-10 h-10 rounded-full"></div>
          <div className="bg-secondary-background w-10 h-10 rounded-full"> 
            <IconButton className="bg-blue-900 w-10 h-10 rounded-full text-secondary-white">
                <AddIcon />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between items-end h-full">
        {/* Badge and Icon */}
        <div className="flex justify-center items-center space-x-2 mt-auto bg-secondary-white rounded-2xl h-[30px] w-[60px]">
          <p>4</p>
          <NotificationsActiveRoundedIcon className='text-secondary-pearl h-[10px] w-[10px]' />
        </div>
      </div>
    </div>
  );
}

export default EfficiencyCard;
