import { useDispatch, useSelector } from "react-redux";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import HBarHome from "../components/HBarHome";
import { useEffect, useState } from "react";
import { setWorkspace } from "../redux/workspaceSlice";
import { useParams } from "react-router-dom";
import workspaceService from "../services/workspaceService";
import PerformanceChart from '../components/PerformanceChart';
import EfficiencyCard from "../components/EfficiencyCard";
import NotificationCard from "../components/NotificationCard";
import Agentcard from "../components/AgentCard";
import { setW } from "../redux/wSlice";

function HomePage() {

    const [workspaceName, setWorkspaceName] = useState('');
    const { name, members, owner, id } = useSelector(state => state.w);
    const { userInfo } = useSelector(state => state.auth);
    const { workspaceId } = useParams();

    const dispatch = useDispatch();

    const handleUpdate = () => {
        const newData = {
            id,
            members,
            owner,
            name: workspaceName
        };
        console.log(newData);
        dispatch(setW(newData));
    };
    
    const editName = (e) => {
        setWorkspaceName(e.target.value);
    }

    useEffect(() => {
        setWorkspaceName(name);
    }, [name]);
    return (
        <div className="h-screen overflow-hidden bg-secondary-background">
            <header className="p-4 bg-white flex justify-between items-center">
                <div className="text-2xl text-primary-bleu font-extrabold">
                    <input
                        onBlur={handleUpdate}
                        type="text"
                        className="outline-none rounded focus:ring-2 focus:ring-primary-jaune"
                        value={workspaceName}
                        onChange={editName}
                    />
                </div>
                <div className="flex items-center">
                    <div className="flex">
                        <button type="button" className="inline-flex items-center px-5 py-2 text font-medium text-center text-white bg-primary-bleu rounded-lg hover:bg-primary-bleu/80">
                            Notifications
                            <span className="inline-flex items-center justify-center w-5 h-5 ms-2 text-xs font-semibold text-primary-bleu bg-primary-jaune rounded-full">
                                2
                            </span>
                        </button>
                    </div>
                    <div className="p-[1px] bg-gray-400 mx-2 h-5">

                    </div>
                    <div className="space-x-1 text-primary-bleu hover:text-primary-jaune hover:underline cursor-pointer">
                        <span><AccountCircleRoundedIcon /></span>
                        <span>{userInfo.email}</span>
                    </div>
                </div>
            </header>
            <div className="p-2">
                <HBarHome />
                <div className="flex items-center space-x-4 mt-4" >
                    <EfficiencyCard />
                    <Agentcard />
                    <NotificationCard />


                </div>
                <PerformanceChart />

            </div>
        </div>
    );
}

export default HomePage;