import axios from "axios";
import { getAccessToken, removeToken } from "./helpers";

const apiClient = axios.create({
	// const backendURL = process.env.REACT_APP_BACKEND_URL;

	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Request interceptors
apiClient.interceptors.request.use(
	config => {
		// Add authorization token to every request
		const token = getAccessToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => Promise.reject(error)
);
// Response interceptor
apiClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			// Demande de rafraîchissement du token
			try {
				const refreshToken = localStorage.getItem('refresh_token');
				const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, { refresh_token: refreshToken });
				console.log('RESPONSE: ', response);
				
				const newAccessToken = response.data.access_token;

				// Mise à jour de l'access_token dans le localStorage
				localStorage.setItem('access_token', newAccessToken);

				// Réessai de la requête originale avec le nouveau token
				originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
				return apiClient(originalRequest);
			} catch (err) {
				console.error(err);
				// Si le refresh échoue, redirige l'utilisateur vers la connexion
				removeToken();
				// window.location.href = '/login';
				return Promise.reject(err);
			}
		}
		return Promise.reject(error);
	}
);

export default apiClient;