import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export function PasswordInput({ placeholder = "Password", className, ...props }) {

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={`relative ${className}`}>
      <input
        type={passwordVisible ? "text" : "password"}
        className="bg-grey border border-secondary-stroke text-secondary-pearl rounded-lg outline-none focus:ring-primary-bleu focus:ring-2 block w-full pe-10 p-4"
        placeholder={placeholder}
        {...props}
      />
      <div onClick={togglePasswordVisibility} className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer">
        {passwordVisible ? <VisibilityOutlinedIcon fontSize='small'/> : <VisibilityOffOutlinedIcon fontSize='small' />}
      </div>
    </div>
  );
}