import { createSlice } from '@reduxjs/toolkit'
import { loginUser, registerUser } from './actions/authActions'
import { getAccessToken, removeToken } from '../utils/helpers';

const accessToken = getAccessToken();

const initialState = {
    userInfo: {},
    accessToken,
    refreshToken: null,
    loading: false,
    success: false,
    error: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.userInfo = null;
            removeToken();
        },
        setUserInfo: (state, { payload }) => {
            state.userInfo = payload;
        },
        setToken: (state, payload) => {
            state.accessToken = payload;
        },
        clearResponse: (state) => {
            state.error = null;
            state.success = null;
            state.loading = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // REGISTER USER
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // LOGIN USER
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.accessToken = action.payload.access_token;
                state.refreshToken = action.payload.refresh_token;
                state.success = true;

                console.log('Access Token:', action.payload.access_token);
                console.log('Refresh Token:', action.payload.refresh_token);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
})

export const { logout, setUserInfo, setToken, clearResponse } = authSlice.actions;
export default authSlice.reducer;