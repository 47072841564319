import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import { useEffect, useState } from 'react';
import agentService from '../../services/agentService';
import scrapingService from '../../services/scrapingService';

function AgentCard({ agent, onStatusChange, onClick }) {
    const [status, setStatus] = useState(agent.status);

    const toggleStatus = (event) => {
        event.stopPropagation();

        const newStatus = status === 'active' ? 'inactive' : 'active';
        setStatus(newStatus);
        const updatesAgent = {
            ...agent,
            status: newStatus
        }
        updateStatus(agent.id, updatesAgent);
    };

    const updateStatus = async (id, data) => {
        setStatus('active');
        onStatusChange(id, 'active');
        try {
            const filter = {
                job_title: agent.keywords,
                location: agent.location
            }
            const scrapeRelust = await scrapingService.scrapeIndeed(filter);
            if (scrapeRelust) {
                setStatus('inactive');
                onStatusChange(id, 'inactive');

            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div onClick={onClick} className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:cursor-pointer hover:shadow-md">
            <div className="p-4 space-y-2">
                <div className='flex justify-between items-center'>
                    <h2 className="text-lg font-semibold text-primary-bleu">{agent.name}</h2>
                    <div>
                        <button onClick={(e) => toggleStatus(e)} className={`${status === 'active' ? 'text-red-500' : 'text-primary-bleu'}`}>
                            {status === 'active' ? <StopCircleRoundedIcon fontSize='large' /> : <PlayCircleRoundedIcon fontSize='large' />}
                        </button>
                    </div>
                </div>

                <div className="flex items-center justify-between text-sm">
                    <span className="text-primary-bleu underline">{agent.site}</span>
                    <div className='flex items-center'>
                        <span className='text-primary-bleu'> {agent.security ? <LockIcon /> : <LockOpenIcon />}</span>
                    </div>
                </div>

                <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">Mots clé</span>
                    <span className="text-primary-bleu">{agent.keywords}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-500">Localisation</span>
                    <span className="text-primary-bleu">{agent.location}</span>
                </div>
            </div>
        </div>
    );
};

export default AgentCard;