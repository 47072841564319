import { createSlice } from '@reduxjs/toolkit';
import { createSite, fetchSites, updateSite, deleteSite } from './actions/siteActions';

const initialState = {
  sites: [],
  loading: false,
  error: null,
};

const siteSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSite.fulfilled, (state, action) => {
        state.loading = false;
        state.sites.push(action.payload); // Ajoute le nouveau site à la liste
      })
      .addCase(createSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.loading = false;
        state.sites = action.payload;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSite.fulfilled,(state, action) =>{
        state.loading = false;
        const index = state.sites.findIndex(site => site.id === action.payload.id);
        if (index !== -1){
          state.sites[index] = action.payload;
        }
      })
      .addCase(updateSite.rejected,(state, action) =>{
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        state.loading = false;
        state.sites = state.sites.filter(site => site.id !== action.meta.arg); // Supprime le site de la liste
      })
      .addCase(deleteSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default siteSlice.reducer;
