function NotFound() {
    return (
        <div className="h-screen flex items-center justify-center">
            <div className="max-w-md w-full text-center">
                <h2 className="text-9xl font-bold text-primary-bleu">404</h2>
                <p className="mt-4 text-3xl font-semibold text-primary-bleu">Page introuvable</p>
                <p className="mt-6 text-base text-gray-600">Désolé, nous n’avons pas pu trouver la page que vous recherchez.</p>
            </div>
        </div>
    );
}

export default NotFound;