import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import GroupIcon from '@mui/icons-material/Group';
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { NavLink } from "react-router-dom";

function MyNavLink({ text, path, Icon }) {

    const activeStyle = 'bg-secondary-white p-2 rounded-lg font-bold text-primary-bleu';

    return (
        <NavLink to={path} className={({ isActive }) => isActive ? activeStyle : 'text-secondary-white'} end>
            {Icon && <Icon />}
        </NavLink>

    );

}

function SideBar() {

    return (
        <div className="h-screen flex flex-col p-3 py-5 bg-primary-bleu items-center justify-between">
            <div className="flex flex-col items-center gap-4">
                <MyNavLink text="Accueil" path={``} Icon={HomeRoundedIcon} />
                <MyNavLink text="Agent" path={`agent`} Icon={SmartToyRoundedIcon} />
                <MyNavLink text="Filtre" path={`filtres`} Icon={FilterListRoundedIcon} />
                <MyNavLink text="Donnees" path={`donnees`} Icon={InsertLinkOutlinedIcon} />
                <MyNavLink text="Clients" path={`clients`} Icon={GroupIcon} />
            </div>


            <div className="flex flex-col gap-4">
                <MyNavLink text="Parametres" path="/parametres" Icon={SettingsOutlined} />
                <MyNavLink text="Quitter" path={'/workspace'} Icon={LogoutOutlined} />
            </div>
        </div>
    );
}

export default SideBar;