import { useEffect, useState } from "react";
import offerService from "../../services/offerService";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import OfferDetails from "./OfferDetails";

function ListOffers() {

    const [offers, setOffers] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [total, setTotal] = useState(0);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [title, setTitle] = useState('');

    const fetchPages = async (page) => {
        try {
            const response = await offerService.getPages(page, pageSize);
            if (response.data) {
                const result = response.data;
                setOffers(result.data);
                setTotalPages(result.pages);
                setHasNext(result.has_next);
                setHasPrev(result.has_prev);
                setTotal(result.total);
            }
        } catch (error) {
            console.log(error)
        }
    };

    const handleOpen = (offer) => {
        setSelected(offer);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleNext = () => {
        if (hasNext) {
            setPage((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (hasPrev) {
            setPage((prev) => prev - 1);
        }
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleSearch = (e) => {
        e.preventDefault();
        fetchFilteredOffers();
    } 

    const fetchFilteredOffers = async () => {
        try {
            const response = await offerService.getFiltered(page, pageSize, title);
            console.log(response);

            if (response.data) {
                const result = response.data;
                setOffers(result.data);
                setTotalPages(result.pages);
                setHasNext(result.has_next);
                setHasPrev(result.has_prev);
                setTotal(result.total);
            }
        } catch (error) {
            console.error(error)
        }
    };

    // useEffect(() => {
    //     fetchOffers();
    // }, []);

    useEffect(() => {
        fetchPages(page);
    }, [page])
    return (
        <div className="h-screen p-4">
            <div className="mx-auto">
                <div className="flex justify-between items-center my-2">
                    <h1 className="text-3xl font-bold text-primary-bleu mb-4">Liste des offres</h1>
                    <div className="flex items-center space-x-2">
                        <div>
                            <form onSubmit={handleSearch}>
                                <div className="flex flex-col w-full max-w-sm mx-auto">
                                    <div className="flex">
                                        <input
                                            value={title}
                                            onChange={(e) => handleTitleChange(e)}
                                            type="text"
                                            placeholder="Mot clé"
                                            className="w-full px-4 py-2 border-2 border-secondary-stroke rounded-l-md focus:outline-none focus:border-primary-jaune"
                                        />
                                        <button className="px-4 py-2 text-white bg-primary-bleu rounded-r-md hover:text-primary-jaune  focus:outline-none focus:ring-2 focus:ring-primary-jaune">
                                            Rechercher
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full bg-white shadow-md overflow-hidden rounded-md">
                        <thead className="bg-primary-bleu text-white">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Titre et Description</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Organisation</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Profil</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Date de publication</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Date d'expiration</th>
                                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Lien</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {offers.map((offer, index) => (
                                <tr key={index} onClick={() => handleOpen(offer)} className="hover:bg-gray-50 cursor-pointer">
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-primary-bleu">{offer.title}</div>
                                        <div className="text-sm text-gray-500">{offer.description.substring(0, 50)}...</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="font-extrabold text-primary-bleu">{offer.company_name}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <span className="text-primary-blue font-bold">{offer.candidate_profile.substring(0, 50)} ...</span>
                                        </div>
                                    </td>
                    
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <span className="text-sm text-gray-900">{offer.publisheddate}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="text-sm text-gray-900">{offer.expireddate}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-wrap">
                                        <a
                                            href={offer.job_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center underline text-primary-bleu hover:text-primary-jaune transition-colors duration-200"
                                        >
                                            {offer.job_link}
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <hr className="mt-2" />
                <div className="flex justify-between items-center mt-2">
                    <div>
                        <span className="p-2 rounded bg-primary-bleu text-white">Total: {total}</span>
                    </div>
                    <div className="space-x-2">
                        <button className={`px-2 py-1 bg-primary-bleu rounded text-sm text-white ${!hasPrev ? 'hidden' : ''}`} onClick={handlePrev} disabled={!hasPrev}>
                            <ArrowBackIosNewRoundedIcon fontSize="small" />
                        </button>
                        <span>Page {page} sur {totalPages}</span>
                        <button className={`px-2 py-1 bg-primary-bleu rounded text-sm text-white ${!hasNext ? 'hidden' : ''}`} onClick={handleNext} disabled={!hasNext}>
                            <ArrowForwardIosRoundedIcon fontSize="small" />
                        </button>
                    </div>
                </div>
            </div>
            <OfferDetails offer={selected} isOpen={open} onClose={handleClose} />
        </div>
    );
}

export default ListOffers;