import React, { useState } from 'react';
import {
  Email, Chat, Videocam, Phone, ArrowDropDown,
  Email as EmailIcon, Phone as PhoneIcon, LocationOn
} from '@mui/icons-material';

const ContactCard = ({ contact }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="relative m-2">
      <div className="max-w-sm bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center">
          <img
            className="w-16 h-16 rounded-full"
            src={contact.avatar}
            alt={`${contact.name} Avatar`}
          />
          <div className="ml-4">
            <h2 className="text-xl font-semibold">{contact.name}</h2>
            <p className="text-gray-500">{contact.position}</p>
            <p className="text-gray-500">{contact.department}</p>
          </div>
        </div>

        <div className="flex justify-around mt-4 text-blue-600">
          <Email className="cursor-pointer" />
          <Chat className="cursor-pointer" />
          <Videocam className="cursor-pointer" />
          <Phone className="cursor-pointer" />
        </div>

        <div className="flex justify-center mt-4">
          <ArrowDropDown
            className="cursor-pointer text-gray-500"
            onClick={toggleDetails}
          />
        </div>

        {showDetails && (
          <div className="absolute bg-white shadow-lg p-4 border rounded-lg top-full left-0 mt-2 z-10 w-full">
            <div className="border-t pt-4">
              <div className="flex items-center mb-2">
                <EmailIcon className="mr-2 text-gray-700" />
                <p className="text-gray-700">{contact.email}</p>
              </div>
              <div className="flex items-center mb-2">
                <PhoneIcon className="mr-2 text-gray-700" />
                <p className="text-gray-700">{contact.phone}</p>
              </div>
              <div className="flex items-center">
                <LocationOn className="mr-2 text-gray-700" />
                <p className="text-gray-700">{contact.location}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactCard;
