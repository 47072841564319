import { useState, useEffect } from "react";
import CountryFilter from "./filters/CountryFilter";
import SectorFilter from "./filters/SectorFilter";
import SizeFilter from "./filters/SizeFilter";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { useDispatch, useSelector } from "react-redux";
import { createConfiguration, fetchConfigurations, updateConfiguration, deleteConfiguration, duplicateConfiguration} from "../redux/actions/filterActions"; // Importer fetchConfigurations
import ConfigurationsList from "../components/ConfigurationsList";
import { Modal, Box, IconButton, TextField } from '@mui/material';
import Close from '@mui/icons-material/Close';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';


function FilterNav({ step, next, prev, onConfirm }) {
    return (
        <div className="flex justify-around">
            {step !== 1 &&
                <button onClick={prev} className="py-2 px-3 bg-primary-bleu rounded-lg text-secondary-white hover:bg-primary-bleu/80">
                    <ArrowBackRoundedIcon fontSize='small' /> Précédent
                </button>
            }
            {step !== 3 &&
                <button onClick={next} className="py-2 px-3 bg-primary-bleu rounded-lg text-secondary-white hover:bg-primary-bleu/80">
                    Suivant <ArrowForwardRoundedIcon fontSize='small' />
                </button>
            }
            {step === 3 &&
                <button onClick={onConfirm} className="py-2 px-3 bg-primary-bleu rounded-lg text-secondary-white hover:bg-primary-bleu/80">
                    Confirmer <DoneRoundedIcon fontSize='small' />
                </button>
            }
        </div>
    );
}

function FilterPage() {

    const [step, setStep] = useState(1);
    const [openModal, setOpenModal] = useState(false);  // État du modal
    const [selectedConfiguration, setSelectedConfiguration] = useState(null); // Config sélectionnée
    const { sectors, countries, size, configurations } = useSelector(state => state.filter); // Récupérer les configurations
    const { userInfo } = useSelector(state => state.auth);
    const dispatch = useDispatch(); // Pour dispatch les actions
    const [duplicateData, setDuplicateData] = useState({
        frequency: 5,
        issecured: false,
        password: '',
        country: ''
    });

    useEffect(() => {
        // Fetch existing configurations when the component mounts
        dispatch(fetchConfigurations());
    }, [dispatch]);

    const showFilter = (name) => {
        switch (name) {
            case 1: 
                return <SectorFilter />;
            case 2:
                return <SizeFilter />;
            case 3:
                return <CountryFilter />;
            default:
                return '404';
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const handleConfirm = () => {
        const result = {
            frequency: sectors,
            issecured: false,
            country: countries.join(', '), 
            small: size.small,
            medium: size.medium,
            large: size.large,
        };

        // Dispatch action to create configuration
        dispatch(createConfiguration(result));
    };

    const handleOpenModal = (config) => {
        setSelectedConfiguration(config);  // Récupérer la configuration sélectionnée
        setOpenModal(true);  // Ouvrir le modal
    };

    const handleCloseModal = () => {
        setOpenModal(false);  // Fermer le modal
    };

    const handleConfigChange = (field, value) => {
        setSelectedConfiguration({ ...selectedConfiguration, [field]: value });
    };

    const handleSaveConfig = () => {
        if (selectedConfiguration) {
            const updatedConfig = {
                frequency: selectedConfiguration.frequency,
                country: selectedConfiguration.country,
                small: selectedConfiguration.small,
                medium: selectedConfiguration.medium,
                large: selectedConfiguration.large,
                issecured: selectedConfiguration.issecured,
            };
    
            // Dispatch de l'action updateConfiguration avec l'ID de la configuration sélectionnée
            dispatch(updateConfiguration({ id: selectedConfiguration.id, data: updatedConfig }));
            handleCloseModal(); // Fermer le modal après la sauvegarde
        }
    };

    const handleDelete = () => {
        if (selectedConfiguration) {
            dispatch(deleteConfiguration(selectedConfiguration.id));
            handleCloseModal();
        }
    };

    const handleDuplicate = () => {
        if (selectedConfiguration) {
            const dataToDuplicate = {
                frequency: selectedConfiguration.frequency,
                issecured: duplicateData.issecured,
                password: duplicateData.password,
                country: duplicateData.country,
                small: selectedConfiguration.small || false,
                medium: selectedConfiguration.medium || false ,
                large: selectedConfiguration.large || false,
            };
    
            dispatch(duplicateConfiguration({ id: selectedConfiguration.id, data: dataToDuplicate }));
            handleCloseModal(); // Ferme le modal après duplication
        }
    };
    

    return (
        <div className="h-screen justify-center items-center bg-secondary-background overflow-auto">
            <div className="flex items-center w-full justify-end">
                    <div className="flex">
                        <button type="button" className="inline-flex items-center px-5 py-2 text font-medium text-center text-white bg-primary-bleu rounded-lg hover:bg-primary-bleu/80">
                            Notifications
                            <span className="inline-flex items-center justify-center w-5 h-5 ms-2 text-xs font-semibold text-primary-bleu bg-primary-jaune rounded-full">
                                2
                            </span>
                        </button>
                    </div>
                    <div className="p-[1px] bg-gray-400 mx-2 h-5">

                    </div>
                    <div className="space-x-1 text-primary-bleu hover:text-primary-jaune hover:underline cursor-pointer">
                        <span><AccountCircleRoundedIcon /></span>
                        <span>{userInfo.email}</span>
                    </div>
            </div>
            {/* Left side - Filters */}
            <div className="w-full p-5 mt-20">
                {showFilter(step)}

                <div className="mt-5">
                    <FilterNav step={step} next={nextStep} prev={previousStep} onConfirm={handleConfirm} />
                </div>
            </div>

            {/* Right side - Configurations List */}
            <div className="w-full p-5">
                <ConfigurationsList configurations={configurations} onConfigClick={handleOpenModal} />
            </div>

            {/* Modal pour afficher la configuration sélectionnée */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    className="bg-secondary-white max-w-lg w-full mx-auto p-6 rounded-lg shadow-lg relative"
                    style={{ top: '50%', transform: 'translateY(-50%)' }}
                >
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center">
                            <span className="font-semibold">Détails de la Configuration</span>
                        </div>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </div>

                    {/* Contenu modifiable de la configuration */}
                    <div className="space-y-4">
                        <div>
                            <label className="block font-semibold mb-1">Fréquence</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={selectedConfiguration ? selectedConfiguration.frequency : ''}
                                onChange={(e) => handleConfigChange('frequency', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">Pays</label>
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={selectedConfiguration ? selectedConfiguration.country : ''}
                                onChange={(e) => handleConfigChange('country', e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">Taille</label>
                            <div className="flex gap-2">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedConfiguration?.small}
                                        onChange={(e) => handleConfigChange('small', e.target.checked)}
                                    />
                                    Small
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedConfiguration?.medium}
                                        onChange={(e) => handleConfigChange('medium', e.target.checked)}
                                    />
                                    Medium
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedConfiguration?.large}
                                        onChange={(e) => handleConfigChange('large', e.target.checked)}
                                    />
                                    Large
                                </label>
                            </div>
                        </div>
                        <div>
                            <label className="block font-semibold mb-1">Sécurisé</label>
                            <input
                                type="checkbox"
                                checked={selectedConfiguration?.issecured}
                                onChange={(e) => handleConfigChange('issecured', e.target.checked)}
                            />
                        </div>
                    </div>

                    {/* Modal Footer Buttons */}
                    <div className="flex justify-between items-center mt-6">
                        <button
                            onClick={handleCloseModal}
                            className="bg-secondary-white text-secondary-pearl font-bold mt-4 px-4 py-2 rounded-xl hover:bg-secondary-background"
                        >
                            Annuler
                        </button>
                        <button
                        onClick={handleSaveConfig}
                            className="bg-primary-bleu text-secondary-white font-bold mt-4 px-4 py-2 rounded-xl hover:bg-primary-bleu"
                        >
                            Sauvegarder
                        </button>
                        <button
                        onClick={handleDuplicate}
                            className="bg-primary-bleu text-secondary-white font-bold mt-4 px-4 py-2 rounded-xl hover:bg-primary-bleu"
                        >
                            Dupliquer
                        </button>
                        <button
                        onClick={handleDelete}
                            className="bg-primary-bleu text-secondary-white font-bold mt-4 px-4 py-2 rounded-xl hover:bg-primary-bleu"
                        >
                            Supprimer
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default FilterPage;
