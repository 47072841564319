import { createSlice } from '@reduxjs/toolkit';
import { createOrganisation, fetchOrganisations, fetchOrganisationById, deleteOrganisation, sendInvitation } from './actions/organisationActions';
const organisationSlice = createSlice({
  name: 'organisations',
  initialState: {
    organisations: [],
    organisation: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrganisation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrganisation.fulfilled, (state, action) => {
        state.loading = false;
        state.organisations.push(action.payload);
      })
      .addCase(fetchOrganisations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrganisations.fulfilled, (state, action) => {
        state.loading = false;
        state.organisations = action.payload;
      })
      .addCase(fetchOrganisations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchOrganisationById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrganisationById.fulfilled, (state, action) => {
        state.loading = false;
        state.organisation = action.payload; // Mettre à jour l'organisation spécifique
      })
      .addCase(fetchOrganisationById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOrganisation.fulfilled, (state, action) => {
        state.organisations = state.organisations.filter(org => org.id !== action.payload);
      })
      builder
      .addCase(sendInvitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendInvitation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendInvitation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });


  },
});

export default organisationSlice.reducer;