import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import EfficiencyCard from '../components/EfficiencyCard';
import NotificationCard from '../components/NotificationCard';
import PerformanceChart from '../components/PerformanceChart';
import NotificationBar from '../components/NotificationBar';
import AgentCard from '../components/AgentCard';
import SEO from '../components/SEO';
import HBarHome from '../components/HBarHome';
function Menu() {
    return (
        <>
            <SEO title="Accueil | iTady" description="Accueil" />
            <div className='h-screen overflow-hidden flex flex-col flex-1 p-3 border-secondary-stroke border-2 rounded-lg bg-secondary-ciel'>
                <HBarHome />
                <div className='flex justify-between items-center gap-3 mt-2'>
                    <EfficiencyCard />
                    <NotificationCard />
                    <AgentCard />
                </div>
                <div className="flex justify-between items-start mt-3 gap-3">
                    <div className='w-full'>
                        <PerformanceChart />
                    </div>
                    <div className='bg-secondary-white rounded-lg border border-secondary-stroke'>
                        <div className='p-4 flex justify-between items-start'>
                            <h3 className='text-lg font-bold'>Notifications recent</h3>
                            <NotificationsActiveRoundedIcon className='' />
                        </div>
                        <div className='overflow-auto 
                        '>
                            <NotificationBar />
                            <NotificationBar />
                            <NotificationBar />
                        </div>
                        <div className='p-4 border-t text-center'>
                            <button className='text-blue-600'>Voir tout</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Menu;