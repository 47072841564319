import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSectors } from '../../redux/filterSlice';

export default function SectorFilter() {
    const { sectors } = useSelector(state => state.filter);
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(sectors || []);

    useEffect(() => {
        setSelectedValue(sectors);
    }, [sectors]);

    const handleInputChange = (event) => {
        const value = event.target.value;

        if (!isNaN(value) || value === '') {
            setSelectedValue(value);
            dispatch(setSectors(value));
        }
    };

    const selectSector = (newValue) => {
        setSelectedValue(newValue);
        dispatch(setSectors(newValue));
    };
    
    return (
        <div>
            <div className="text-center mb-10">
                <h1 className="text-3xl font-extrabold">Dans quel secteur vous voulez trouver votre site ?</h1>
                <h3>Indiquez la fréquence à laquelle vous souhaitez établir ou rechercher un site.</h3>
            </div>
            <div className="flex justify-center">
                <div className="w-[500px] max-w-[500px]">
                    <input
                        type="number" 
                        value={selectedValue}
                        onChange={handleInputChange}
                        placeholder="Entrer la fréquence de recherche"
                        className="border rounded-lg w-full p-2" 
                        min="0" 
                        step="1" 
                    />
                </div>
            </div>
        </div>
    );
}
