// organisationActions.js
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const createOrganisation = createAsyncThunk(
  'organisations/create',
  async (organisationData, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      };

      const response = await axios.post(
        `${backendURL}/organisations`,
        organisationData,
        config
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Action pour récupérer les organisations
export const fetchOrganisations = createAsyncThunk(
    'organisations/fetchAll',
    async (_, { rejectWithValue }) => {
      try {
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) throw new Error('No access token found');
  
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        };
  
        const response = await axios.get(`${backendURL}/organisations`, config);
        console.log(response.data);
        return response.data; // On suppose que la réponse contient un tableau d'organisations
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );

  export const fetchOrganisationById = createAsyncThunk(
    'organisations/fetchById',
    async (organisationId, { rejectWithValue }) => {
      try {
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) throw new Error('No access token found');
  
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        };
  
        const response = await axios.get(
          `${backendURL}/organisations/${organisationId}`,
          config
        );
        return response.data;
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );




  export const deleteOrganisation = createAsyncThunk(
    'organisations/delete',
    async (organisationId, { rejectWithValue }) => {
      try {
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) throw new Error('No access token found');
  
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        };
  
        await axios.delete(`${backendURL}/organisations/${organisationId}`, config);
        return organisationId; // On renvoie l'ID pour pouvoir mettre à jour le store
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );
  
  export const sendInvitation = createAsyncThunk(
    'contacts/invite',
    async ({ email, name }, { rejectWithValue }) => {
      try {
        const accessToken = localStorage.getItem('access_token');
  
        if (!accessToken) throw new Error('No access token found');
  
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        };
  
        const response = await axios.post(
          `${backendURL}/contacts/invite`,
          { mail: email, name },
          config
        );
        console.log(response.data);
        return response.data;
      } catch (error) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );
  
