import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const createSite = createAsyncThunk(
  'sites/create',
  async ({ url, name }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      };

      const response = await axios.post(
        `${backendURL}/sites`,
        {
          url,
          name,
          organisation_id: "8a1f346d-adee-4f1c-b10b-ae9dca1e16f3", // ID prédéfini
          configuration_id: "3652315b-a4c9-47d0-9f68-7cb31e2f5f45", // ID prédéfini
        },
        config
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



export const fetchSites = createAsyncThunk(
  'sites/fetchSites',
  async (_, { rejectWithValue}) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');
      const config = {
        headers :{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      };

      const response =await axios.get(`${backendURL}/sites`, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message){
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateSite = createAsyncThunk(
  'sites/update',
  async ({ id, url, name, organisation_id, configuration_id }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      };

      const response = await axios.put(
        `${backendURL}/sites/${id}`,
        {
          url,
          name,
          organisation_id,
          configuration_id,
        },
        config
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteSite = createAsyncThunk(
  'sites/delete',
  async (id, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem('access_token');

      if (!accessToken) throw new Error('No access token found');

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      };

      const response = await axios.delete(`${backendURL}/sites/${id}`, config);
      return response.data; // Vous pouvez retourner une confirmation ou l'objet supprimé
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);