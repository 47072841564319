import { useDispatch, useSelector } from 'react-redux';
import Multiselect2 from '../../components/inputs/MultiSelect2';
import { useEffect, useState } from 'react';
import { setCountries } from '../../redux/filterSlice';

const countries1 = [
    "Madagascar",
    "France",
    "Canada",
    "USA"
]
function CountryFliter() {

    const [selectedValue, setSelectedValue] = useState([]);
    const { countries } = useSelector(state => state.filter);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedValue(countries);
        
    }, [countries]);

    const selectCountries = (newValue) => {
        setSelectedValue(newValue);
        dispatch(setCountries(newValue));
    };

    return (
        <div>
            <div className="text-center mb-10">
                <h1 className="text-3xl font-extrabold">Dans quel pays vous voulez trouver votre site ?</h1>
                <h3>Sélectionnez le pays où vous aimeriez localiser votre site</h3>
            </div>
            <div className="flex justify-center">
                <div className="w-[500px] max-w-[500px]">
                    <Multiselect2
                    value={selectedValue}
                    onChange={selectCountries} 
                    options={countries1} 
                    placeholder="Rechercher un pays" />
                </div>
            </div>
        </div>
    );
}

export default CountryFliter;