import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import agentService from "../../services/agentService";
import { useSnackbar } from "../../context/SnackBarProvider";

function AgentDetail({ isOpen, onClose, onSubmit, agent }) {

    const showAlert = useSnackbar();

    const [formValues, setFormValues] = useState({
        email: '',
        keywords: '',
        location: '',
        name: '',
        password: '',
        security: false,
        site: '',
        status: '',
    });

    const handleDelete = async () => {
        try {
            const response = await agentService.delete(agent.id);
            console.log(response);
            onSubmit();
            showAlert('Succès', 'info');
            onClose();
            
        } catch (error) {
            showAlert('Erreur !!!', 'error')
        }
    };

    const handleUpdate = async () => {
        try {
            const response = await agentService.update(agent.id, formValues);
            if (response) {
                showAlert('Succès', 'success');
                onSubmit();   
            }
        } catch (error) {
            showAlert('Erreur !!!', 'error')
        }
    }

    useEffect(() => {
        if (agent) {
            setFormValues({
                email: agent.email || '' ,
                keywords: agent.keywords || '',
                location: agent.location || '',
                name: agent.name || '',
                password: agent.password || '',
                security: agent.security || false,
                site: agent.site || '',
                status: agent.status || '',
            });
        }
    }, [agent])

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle className='text-primary-bleu'>Detail d'un agent</DialogTitle>

            <DialogContent>
                <form className='p-2'>
                    <div className="mb-3">
                        <TextField
                            id="outlined-name"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            color="secondary"
                            label="Nom de l'agent"
                            fullWidth
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <TextField
                            id="outlined-site"
                            name="site"
                            value={formValues.site}
                            onChange={handleChange}
                            color="secondary"
                            label="Site"
                            fullWidth
                            required
                        />
                    </div>

                    <hr className="m-2" />

                    <div className="mb-3">
                        <TextField
                            id="outlined-keywords"
                            name="keywords"
                            value={formValues.keywords}
                            onChange={handleChange}
                            color="secondary"
                            label="Mots clés"
                            fullWidth
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <TextField
                            id="outlined-location"
                            name="location"
                            value={formValues.location}
                            onChange={handleChange}
                            color="secondary"
                            label="Localisation"
                            fullWidth
                            required
                        />
                    </div>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={formValues.security}
                                onChange={handleChange}
                                name="security"
                                color='secondary'
                            />
                        }
                        label="Auhtentification"
                    />

                    <div className={`${!formValues.security ? 'hidden' : 'block'}`}>
                        <div className="my-2 flex justify-between gap-2">
                            <TextField
                                id="outlined-email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                color="secondary"
                                label="Email"
                                placeholder="Entrer l'email pour se connecter"
                                fullWidth
                                required
                            />

                            <TextField
                                id="outlined-password"
                                name="password"
                                type="password"
                                value={formValues.password}
                                onChange={handleChange}
                                color="secondary"
                                label="Mot de passe"
                                placeholder="Enter le mot de passe"
                                fullWidth
                                required
                            />
                        </div>
                    </div>

                </form>
            </DialogContent>

            <DialogActions>
                <div className='flex justify-between gap-3'>
                    <div>
                        <Button onClick={onClose}>Fermer</Button>
                    </div>
                    <div>
                        <Button onClick={handleDelete} variant='contained' color="error">Supprimer</Button>
                    </div>
                    <div>
                        <Button onClick={handleUpdate} variant='contained'>Enregistrer</Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default AgentDetail;