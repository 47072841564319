import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import { useState } from "react";
import { useSnackbar } from "../../context/SnackBarProvider";
import useConfirmDialog from "../../hook/useConfirm";
import { inviteMembers } from "../../redux/actions/workspaceActions";
import { useDispatch, useSelector } from "react-redux";


function InviteMembers() {

    const [members, setMembers] = useState([]);
    const [email, setEmail] = useState('');
    const dispatch = useDispatch(); 
    const workspaceId = useSelector((state) => state.workspace.id); 
    const accessToken = useSelector((state) => state.auth.accessToken);


    const showAlert = useSnackbar();
    const {confirmDialog, ConfirmationDialog } = useConfirmDialog();

    const invite = async (e) => { // Déclarez la fonction comme asynchrone
        e.preventDefault();
        if (!members.includes(email)) {
            setMembers([...members, email]);
            setEmail('');
            try {
                await dispatch(inviteMembers({ workspaceId, email })); // Utilisez `workspaceId` ici
                showAlert('Invitation envoyée avec succès', 'success');
            } catch (error) {
                showAlert('Erreur lors de l\'envoi de l\'invitation', 'error');
            }
        } else {
            showAlert('Email déjà invité !', 'warning');
        }
    };

    const removeMember = async (email) => {
        const confirm = await confirmDialog('Voulez-vous vraiment supprimer cet élément ?');
        if (confirm) {
            setMembers(members.filter((m) => m !== email));
        }
    }; 

    return (
        <div className="min-h-screen flex flex-col bg-white text-primary-bleu">
            <header className="py-6 px-4 sm:px-6 lg:px-8 bg-gray-100">
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <Link to={`/workspace/1`} className="text-primary-bleu hover:text-primary-jaune transition-colors font-extrabold">
                        <ArrowBackRoundedIcon />
                        <span className="underline"> Accueil</span>
                    </Link>
                </div>
            </header>

            <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center text-primary-bleu">
                    Participants
                </h2>
                <div className="flex justify-center">
                    <form onSubmit={invite} className="w-[700px] max-w-[700px]">
                        <div className="mb-5">
                            <label className="font-extrabold mb-2">Inviter des participants</label>

                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center text-secondary-raven ps-3 pointer-events-none">
                                    <AlternateEmailRoundedIcon />
                                </div>
                                <input
                                    type="email"
                                    id="search"
                                    className="block w-full p-4 ps-10 text-sm text-secondary-pearl border border-gray-300 rounded-lg focus:outline-none focus:ring-2 bg-gray-50 focus:ring-primary-bleu"
                                    placeholder="Entrer l'email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary-jaune hover:bg-primary-jaune/80 font-medium rounded-lg text-sm px-4 py-2">Inviter</button>
                            </div>
                        </div>
                        <div>
                            <label className="font-extrabold mb-2">Participants</label>
                            <div className="p-2 flex flex-col gap-2">
                                {
                                    members.map((email) => (
                                        <div key={email} className="flex justify-between p-3 border border-secondary-stroke rounded-md">
                                            <div className="space-x-2">
                                                <span>{email}</span>
                                                <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded border border-yellow-500">En attente</span>
                                            </div>
                                            <div className="space-x-2">
                                                <button type="button" onClick={() => removeMember(email)}><HighlightOffIcon /></button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </main>

            <footer className="py-6 px-4 sm:px-6 lg:px-8 text-center bg-gray-100">
                <p className="text-primary-bleu/60">
                    © 2024 AKATA GOAVANA. All rights reserved.
                </p>
            </footer>
            <ConfirmationDialog />
        </div>
    );
}

export default InviteMembers;