import React, { useState, useCallback } from 'react';
import ConfirmDialog from './ConfirmDialog'; // Le composant créé ci-dessus

// Hook personnalisé pour gérer le ConfirmDialog
const useConfirmDialog = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [resolvePromise, setResolvePromise] = useState(null);

  // Ouvre le dialogue et retourne une promesse
  const confirmDialog = useCallback((message) => {
    setDialogMessage(message);
    setDialogOpen(true);
    return new Promise((resolve) => {
      setResolvePromise(() => resolve);
    });
  }, []);

  // Appelé lorsque l'utilisateur confirme
  const handleConfirm = () => {
    setDialogOpen(false);
    if (resolvePromise) resolvePromise(true); // Résoudre la promesse avec "true"
  };

  // Appelé lorsque l'utilisateur annule
  const handleClose = () => {
    setDialogOpen(false);
    if (resolvePromise) resolvePromise(false); // Résoudre la promesse avec "false"
  };

  // Rendre le composant ConfirmDialog
  const ConfirmationDialog = () => (
    <ConfirmDialog
      open={dialogOpen}
      message={dialogMessage}
      onClose={handleClose}
      onConfirm={handleConfirm}
    />
  );

  return { confirmDialog, ConfirmationDialog };
};

export default useConfirmDialog;
